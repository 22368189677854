import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {OrderItem} from "../../models/order-item";
import {Product} from "../../models/product";
import {CartService} from "../cart/service/cart.service";
import {Router} from "@angular/router";
import {CheckoutService} from "./service/checkout.service";
import {OrderCreationRequest} from "../../models/order";
import {AuthService} from "@auth0/auth0-angular";
import {Carousel, initTE, Modal, Toast} from "tw-elements";
import {ProfileService} from "../profile/service/profile.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {goToUrl} from "../../services/util/util";
import {CouponCodeRequest} from "../../models/coupon-code-request";
import {Customer} from "../../models/customer";
import {FBQ} from "../../models/fbq";

declare let fbq: FBQ;

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css'],
    animations: [
        trigger('slideDown', [
            transition(':enter', [
                style({transform: 'translateY(-50%)', opacity: 0}),
                animate('0.7s')
            ])
        ])]
})
export class CheckoutComponent implements OnInit {

    customer?: Customer;

    order: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        surname: new UntypedFormControl('', [Validators.required]),
        phoneNumber: new UntypedFormControl('', [Validators.required,]),
        address: new UntypedFormControl('', [Validators.required]),
        city: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', [Validators.email]),
        couponCode: new UntypedFormControl(''),
        usePoints: new UntypedFormControl(false)
    });

    orderPrice: number = 0

    deliveryExpenses: number = 150;

    totalPrice: number = 0;

    orderItems: OrderItem[] = [];

    product?: Product;

    toastMessage: string = '';

    isLoading: boolean = false;

    isCouponCodeUsed: boolean = false;

    oldPrice: number = 0;

    referrer?: Customer;

    constructor(
        private readonly cartService: CartService,
        private readonly checkoutService: CheckoutService,
        protected readonly router: Router,
        public auth: AuthService,
        private readonly profileService: ProfileService) {
    }

    ngOnInit(): void {
        initTE({Toast, Carousel});
        this.setCustomerValues();
        this.orderItems = this.cartService.retrieveItemsFromLocalStorage();
        this.calculateTotal();
    }

    get nameControl(): UntypedFormControl {
        return this.order.get('name') as UntypedFormControl;
    }

    get surnameControl(): UntypedFormControl {
        return this.order.get('surname') as UntypedFormControl;
    }

    get phoneNumberControl(): UntypedFormControl {
        return this.order.get('phoneNumber') as UntypedFormControl;
    }

    get addressControl(): UntypedFormControl {
        return this.order.get('address') as UntypedFormControl;
    }


    get cityControl(): UntypedFormControl {
        return this.order.get('city') as UntypedFormControl;
    }

    get emailControl(): UntypedFormControl {
        return this.order.get('email') as UntypedFormControl;
    }

    get couponCodeControl(): UntypedFormControl {
        return this.order.get('couponCode') as UntypedFormControl;
    }

    get usePointsControl(): UntypedFormControl {
        return this.order.get('usePoints') as UntypedFormControl;
    }

    setCustomerValues() {
        return this.auth.user$.subscribe(
            (user) => {
                this.profileService.getCustomerByEmail(user?.email).subscribe((customer) => {
                    this.nameControl.setValue(customer.name)
                    this.surnameControl.setValue(customer.surname)
                    this.emailControl.setValue(customer.email)
                    this.addressControl.setValue(customer.address)
                    this.cityControl.setValue(customer.city)
                    this.phoneNumberControl.setValue(customer.phoneNumber)
                    this.customer = customer;
                })
            })
    }

    setOrderItemImage(orderItem: OrderItem) {
        switch (orderItem.color) {
            case 'bela':
                return 'assets/images/' + orderItem?.product?.category?.toLowerCase()?.replace('_', '-') + '/' + (orderItem?.product?.images?.[0].replace("black", "white") ?? '') + '.webp'
            default:
                return 'assets/images/' + orderItem?.product?.category?.toLowerCase()?.replace('_', '-') + '/' + (orderItem?.product?.images?.[0] ?? '') + '.webp'
        }
    }

    setProductCategory(category: string | undefined) {
        switch (category) {
            case 'T_SHIRT':
                return "Маица - "
            case 'HOODIE':
                return "Дуксер - "
            case 'SWEAT_SHIRT':
                return "Блузон - "
            case 'JERSEY':
                return "Дрес - "
            case 'FLAG':
                return "Знаме - "
            default :
                return ""
        }
    }

    calculateTotal() {
        this.totalPrice = 0;
        this.orderPrice = 0;

        for (const orderItem of this.orderItems) {
            if (orderItem.product && orderItem.product.price && orderItem.quantity) {
                this.orderPrice += orderItem.product.price * orderItem.quantity;
            }
        }
        if (this.orderPrice < 1400) {
            this.totalPrice += this.deliveryExpenses;
        }

        this.totalPrice += this.orderPrice;
    }

    submitOrder(): void {
        this.isLoading = true;

        const orderCreationRequest: OrderCreationRequest = {
            customer: {
                id: this.customer?.id,
                name: this.nameControl.value,
                surname: this.surnameControl.value,
                phoneNumber: this.phoneNumberControl.value,
                address: this.addressControl.value,
                city: this.cityControl.value,
                email: this.emailControl.value,
                totalPoints: this.customer?.totalPoints ?? 0,
                currentPoints: this.customer?.currentPoints ?? 0,
                referrer: this.referrer,
                couponCode: this.customer?.couponCode,
            },
            couponCode: this.referrer?.couponCode ? `${this.referrer.couponCode}` : "",
            orderItems: this.orderItems,
            totalPrice: this.totalPrice,
            usePoints: this.usePointsControl.value,
            orderPrice: this.orderPrice,
            isCouponCodeUsed: this.isCouponCodeUsed
        };

        this.checkoutService.submitOrder(orderCreationRequest)
            .subscribe({
                next: () => {
                    this.openToast("Вашата нарачка е пратена");
                    this.cartService.clearLocalStorage();
                    fbq('track', 'Purchase', {
                        value: this.totalPrice,
                        currency: 'MKD',
                        contents: this.orderItems.map(orderItem => ({
                            id: orderItem.product?.id,
                            quantity: orderItem.quantity,
                        })),
                        content_type: 'product',
                    });
                    setTimeout(() => {
                        this.goToUrl(this.router, '/uspesna-naracka');
                    }, 1500);
                },
                error: () => {
                    this.openToast("Грешка при праќање на нарачка");
                    this.isLoading = false;
                },
                complete: () => this.isLoading = false
            });
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }

    async openConfirmationModal(): Promise<void> {
        if (this.couponCodeControl.value && !this.isCouponCodeUsed) {
            const isCouponApplied = await this.applyCoupon();
            if (!isCouponApplied) {
                this.isLoading = false;
                return;
            }
        }
        if (this.order.status === 'VALID' && this.orderItems.length > 0) {
            const confirmationModal = new Modal(document.getElementById("confirmationModal"));
            confirmationModal.show();
        } else {
            if (this.order.get('email')?.hasError('email')) {
                this.openToast('Невалиден емаил');
            } else if (this.order.get('phoneNumber')?.hasError('required')) {
                this.openToast('Внеси телефонски број');
            } else if (this.order.invalid) {
                this.openToast('Пополни ја формата.');
            } else {
                this.openToast('Додади производи во кошничката.');
            }
        }
    }

    applyCoupon(): Promise<boolean> {
        this.isLoading = true;
        const couponCode = this.couponCodeControl.value;

        return new Promise((resolve) => {
            if (!couponCode) {
                this.openToast('Внеси купон код');
                this.isLoading = false;
                resolve(false);
                return;
            }

            if (couponCode === this.customer?.couponCode) {
                this.openToast("Не можеш да го користиш сопствениот купон код");
                this.isLoading = false;
                return;
            }

            const couponCodeRequest: CouponCodeRequest = {
                couponCode: couponCode,
                totalPrice: this.totalPrice
            };

            this.checkoutService.applyCoupon(couponCodeRequest).subscribe({
                next: (response) => {
                    if (response.totalPrice !== this.totalPrice) {
                        this.oldPrice = this.totalPrice;
                        this.totalPrice = response.totalPrice;
                        this.isCouponCodeUsed = true;
                        this.referrer = response.referrer;
                        resolve(true);
                    } else {
                        this.isCouponCodeUsed = false;
                        this.openToast(`Невалиден купон код: ${couponCode}`);
                        resolve(false);
                    }
                },
                error: (e) => {
                    this.openToast(`Невалиден купон код: ${e.message}`);
                    resolve(false);
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
        });
    }

    handleCheckboxChange(isChecked: boolean): void {
        this.usePointsControl.setValue(isChecked);
    }

    protected readonly goToUrl = goToUrl;
}