import {Component, OnInit} from '@angular/core';
import {Carousel, initTE} from "tw-elements";

@Component({
    selector: 'app-social-links',
    templateUrl: './social-links.component.html',
    styleUrl: './social-links.component.css'
})
export class SocialLinksComponent implements OnInit {

    ngOnInit(): void {
        initTE({Carousel});
    }

}
